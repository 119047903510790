import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-accessdenied',
    templateUrl: './accessdenied.component.html',
    standalone: false
})
export class AccessDeniedComponent implements OnInit {

  constructor(
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Access Denied');
  }
}
